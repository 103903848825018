import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

//Eth Connections
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutSignin from './layouts/LayoutSignin';

// Views
import Home from './views/Home';
import Poems from './views/Poems';
import Login from './views/Login';
import Signup from './views/Signup';

import Shop from './views/Shop';
import Claim_NFT from './views/Claim_NFT';

//Other
import Signed_Book from './views/Signed_Book';

//Poems
import Fuck_James_Blunt from './Poems/Fuck_James_Blunt';
import The_Ones_to_Blame from './Poems/The_Ones_to_Blame';
import Heart_Shaped_Chocolates_For_One from './Poems/Heart_Shaped_Chocolates_For_One';
import To_Peacock from './Poems/To_Peacock';
import The_Magician from './Poems/The_Magician';
import Four_Silhouettes from './Poems/Four_Silhouettes';
import Trick_or_Treat from './Poems/Trick_or_Treat';
import Love_Does_Not_Compute from './Poems/Love_Does_Not_Compute';
import One_Zero_One_Zero_One from './Poems/10101';
import Lights_Out from './Poems/Lights_Out';
import Clay_People from './Poems/Clay_People';
import Spin_to_Win from './Poems/Spin_to_Win';
import Fireworks from './Poems/Fireworks';
import Funfair from './Poems/Funfair';
import Missed_Minutes from './Poems/Missed_Minutes';
import The_Gift from './Poems/The_Gift';
import The_Cutlery_Drawer from './Poems/The_Cutlery_Drawer';
import Pause from './Poems/Pause';
import Home_Poem from './Poems/Home_Poem';
import Heart from './Poems/Heart';
import Pipes from './Poems/Pipes';
import Ground_Zero from './Poems/Ground_Zero';
import Statistically_Yours from './Poems/Statistically_Yours';
import Digital_Dating from './Poems/Digital_Dating';
import The_Fig from './Poems/The_Fig';
import First_Kiss from './Poems/First_Kiss';
import See_saw from './Poems/See_saw';
import Compliments from './Poems/Compliments';
import The_Beautiful_Mundane from './Poems/The_Beautiful_Mundane';
import Fragile_Perfection from './Poems/Fragile_Perfection';
import On_Marriage from './Poems/On_Marriage';
import The_Future from './Poems/The_Future';
import To_the_Core from './Poems/To_the_Core';


function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider);
}

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <Web3ReactProvider getLibrary={getLibrary}>
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/Poems" component={Poems} layout={LayoutDefault} />
            <AppRoute exact path="/Shop" component={Shop} layout={LayoutDefault} />
            <AppRoute exact path="/Claim_NFT" component={Claim_NFT} layout={LayoutDefault} />




            <AppRoute exact path="/Signed_Book" component={Signed_Book} layout={LayoutDefault} />

            <AppRoute exact path="/Poems/Fuck_James_Blunt" component={Fuck_James_Blunt} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Heart_Shaped_Chocolates_For_One" component={Heart_Shaped_Chocolates_For_One} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/To_Peacock" component={To_Peacock} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Magician" component={The_Magician} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Four_Silhouettes" component={Four_Silhouettes} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Trick_or_Treat" component={Trick_or_Treat} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Love_Does_Not_Compute" component={Love_Does_Not_Compute} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/10101" component={One_Zero_One_Zero_One} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Lights_Out" component={Lights_Out} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Clay_People" component={Clay_People} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Spin_to_Win" component={Spin_to_Win} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Fireworks" component={Fireworks} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Funfair" component={Funfair} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Missed_Minutes" component={Missed_Minutes} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Gift" component={The_Gift} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Cutlery_Drawer" component={The_Cutlery_Drawer} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Pause" component={Pause} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Heart" component={Heart} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Pipes" component={Pipes} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Ground_Zero" component={Ground_Zero} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Statistically_Yours" component={Statistically_Yours} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Digital_Dating" component={Digital_Dating} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Fig" component={The_Fig} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/First_Kiss" component={First_Kiss} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/See_saw" component={See_saw} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Compliments" component={Compliments} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Beautiful_Mundane" component={The_Beautiful_Mundane} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Fragile_Perfection" component={Fragile_Perfection} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/On_Marriage" component={On_Marriage} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Future" component={The_Future} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/The_Ones_to_Blame" component={The_Ones_to_Blame} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/To_the_Core" component={To_the_Core} layout={LayoutDefault} />
            <AppRoute exact path="/Poems/Home_Poem" component={Home_Poem} layout={LayoutDefault} />



            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />
          </Switch>
        )} />
        </Web3ReactProvider>
    );
  }
}

export default withRouter(props => <App {...props} />);

import React from 'react';
import { useState } from "react";
import Button from '../../components/elements/Button';
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Connectors"
import { shortenAddress } from '../../utils/shortenAddress';
import classNames from 'classnames';
import Input from '../../components/elements/Input';
import LDNCNFTlogo from '../../assets/Poem_Images/Book_Owner.png';


import { ethers } from 'ethers';

import contractABI from '../../assets/Contracts/answerchecker2.json';
const ACcontractAddress = "0xbAEEfdb9a08bf8f2776E20431236471C94fA3C6F";
const ACabi = contractABI;



const MintNFT = () => {
  const { activate, active, account, deactivate } = useWeb3React();

  const [AcDisplayanswer, setAcDisplayanswer] = useState("");

  const [IsImageActive, setIsImageActive] = useState(false);

  const [UserETHAddress, setAddress] = useState("")





  function NFTMintPicHandler() {
     setIsImageActive(true);
   }



const BlockExploreLink = async () => {
  try {
     const { ethereum } = window;

     if (ethereum) {
       const provider = new ethers.providers.Web3Provider(ethereum);
       const signer = provider.getSigner();
       setAddress(await signer.getAddress());
       let URLblockexplorertop = 'https://gnosisscan.io/token/';
       let URLblockexplorermid = '?a=';
       let URLblockexplorerFull = URLblockexplorertop.concat(ACcontractAddress).concat(URLblockexplorermid).concat(UserETHAddress);
       window.open(URLblockexplorerFull,'_blank')

     }
     } catch (err) {
         console.log(err);
     }
   }



  /// if not working remember to change contracyt address and set the bloomin' answers!

 const CheckanswerHandler = async () => {
   try {
      const { ethereum } = window;

      if (ethereum) {

  const acuserAnswer1 = document.getElementById('Answer1').value;
  const acuserAnswer2 = document.getElementById('Answer2').value;
  const acuserAnswer3 = document.getElementById('Answer3').value;
  console.log(acuserAnswer1, acuserAnswer2, acuserAnswer3);


  //  const { ethereum } = window;
     const provider = new ethers.providers.Web3Provider(ethereum);
   const signer = provider.getSigner();
   setAddress(await signer.getAddress());

    const ACContract = new ethers.Contract(ACcontractAddress, ACabi, signer);
    const tx = await ACContract.userAnswer(acuserAnswer1, acuserAnswer2, acuserAnswer3)
    setAcDisplayanswer('Submitting to blockchain, please wait...')
    const receipt = await tx.wait()
    console.log(receipt)
    var eventCorrectOutput = receipt.events.[0].args.[1];

    if(eventCorrectOutput == true) {
      setAcDisplayanswer('Congratulations! You are correct, you can now mint your token if you have not done so already.')
  
    } else {
  
      setAcDisplayanswer('You are incorrect, please try again.')
    }

 }

    } catch (err) {
      setAcDisplayanswer('Answers not submitted, make sure you are using the Gnosis network and have enough Ether.')

      console.log(err);
    }

}



const MintSubmit = async () => {
  try {
     const { ethereum } = window;

     if (ethereum) {
const { ethereum } = window;
 const provider = new ethers.providers.Web3Provider(ethereum);
const signer = provider.getSigner();
setAddress(await signer.getAddress())

const ACContract = new ethers.Contract(ACcontractAddress, ACabi, signer);
const tx = await ACContract.safeMint()
setAcDisplayanswer('Submitting to blockchain, please wait...')
const receipt = await tx.wait()
console.log(receipt)
NFTMintPicHandler()
setAcDisplayanswer('Congratulations your NFT has been minted!')
}

} catch (err) {
  setAcDisplayanswer('Error: NFT not minted. You may have already minted this NFT.')

  console.log(err);
}

}


  if (active) {

    return (

            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form>
                    <fieldset>
                    <div className="mb-16 text-sm">
                      Question 1: What is the last word of the poem 10101? 
                    </div>
                      <div className="mb-12">
                        <Input
                        id="Answer1"
                          type="text"
                           />
                            </div>
                            <div className="mb-16 text-sm">
                              Question 2: What is the last word of the poem Funfair?
                            </div>
                            <div className="mb-12">
                      <Input

                      id="Answer2"
                        type="text"
                         />
                        </div>
                        <div className="mb-16 text-sm">
                          Question 3: What is the last word of the poem Heart?
                        </div>

                        <div className="mb-12">

                    <Input
                    id="Answer3"
                      type="text"
                       />
                  </div>

                    <div className="mt-24 mb-32">
                        <Button
                        type="button"
                      onClick={CheckanswerHandler}
                        color="primary" wide

                        >Check Answers</Button>
                      </div>
                      <div>
                      <h4>  {AcDisplayanswer} </h4> </div>
                      {IsImageActive && (
      <img
        src={LDNCNFTlogo}
              />
    )}

                    </fieldset>


                  </form>


                  <div className="mt-24 mb-32">


                    <Button color="primary" wide
                    onClick={MintSubmit}

                    >Mint NFT</Button>
                  </div>
                  <div id="ac-display-mintInfo" > </div>

                  <div className="mt-24 mb-32">
                    <Button onClick={BlockExploreLink} color="primary" wide>View NFT on Gnosisscan</Button>
                  </div>
                </div>
              </div>
            </div>


    );
  }
  return (
      <div>
      </div>
  );
};

export default MintNFT;

import React from 'react';
// import section header
import GenericSection from '../components/sections/GenericSection';

// import some required elements
import Image from '../components/elements/Image';
import Button from '../components/elements/Button';
import ETHLogin from '../utils/ETHLogin';
import LDNCPoem from '../components/sections/LDNCPoem';
import LDNCPoemInstructions from '../components/sections/LDNCPoemInstructions';



class Love_Does_Not_Compute extends React.Component {



  render() {



    return (
      <React.Fragment>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Love: Does Not Compute</h2>
            <h4 className="mt-0">Owner: TBC </h4>
            <Button color="dark" wideMobile>View at OpenSea</Button>

            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/Poem_Images/Love_Does_Not_Compute.png')}
                alt="Love: Does Not Compute"
                width={712}
                height={400} />
            </figure>
            <h4 className="mt-0">Poem Smart Contract Interaction</h4>
            <p className="mt-0">This poem is a fully working smart contract </p>
            <p className="mt-0">Follow the instructions below carefully to interact with the smart contract on the blockchain. </p>
              <LDNCPoemInstructions/>

              <br />
              <ETHLogin/>
              <br />
              <LDNCPoem/>



          </div>
        </GenericSection>






      </React.Fragment>
    );
  }
}

export default Love_Does_Not_Compute;

import React from 'react';
import { useState, useEffect} from "react";
import Button from '../../components/elements/Button';
import Select from '../../components/elements/Select';
import Modal from '../../components/elements/Modal';
import Accordion from '../../components/elements/Accordion';
import AccordionItem from '../../components/elements/AccordionItem';
import { useWeb3React } from "@web3-react/core"
import { injected } from "../../components/Connectors"
import { shortenAddress } from '../../utils/shortenAddress';
import classNames from 'classnames';
import Input from '../../components/elements/Input';

import { ethers } from 'ethers';
//import Web3 from 'web3';

import contractABI from '../../assets/Contracts/ldncpoem.json';
const LDNCPoemcontractAddress = "0x09f614fC99C923AB4dbbf36a14968602dC476cA3";
const LDNCPoemabi = contractABI;


const LDNCPoem = () => {
  const { activate, active, account, deactivate } = useWeb3React();

  const [LDNCDisplayYouResult, setLDNCDisplayYouResult] = useState("");


  const [LDNCDisplayThemResult, setLDNCDisplayThemResult] = useState("");


  const [LDNCDisplayFinalResult, setLDNCDisplayFinalResult] = useState("");



const LDNCPoemYouHandler = async () => {
  try {
     const { ethereum } = window;

     if (ethereum) {

  const myName = document.getElementById('myName').value;
  const myAge = document.getElementById('myAge').value;
  const myLatitude = document.getElementById('myLatitude').value;
  const myLongitude = document.getElementById('myLongitude').value;
  const myWants = document.getElementById('myWants').value;
  const myGives = document.getElementById('myGives').value;
  const myReady = document.getElementById('myReady').value;

  //const LDNCDisplayYouResult = "test";


  console.log(myName, myAge, myLatitude, myLongitude, myWants, myGives, myReady)


    const { ethereum } = window;
     const provider = new ethers.providers.Web3Provider(ethereum);
   const signer = provider.getSigner();
    const LDNCPoemContractYou = new ethers.Contract(LDNCPoemcontractAddress, LDNCPoemabi, signer);
    const tx = await LDNCPoemContractYou.You(myName, myAge, myLatitude, myLongitude, myWants, myGives, myReady)
    setLDNCDisplayYouResult('Submitting to blockchain, please wait...')
    const receipt = await tx.wait()
    console.log(receipt)
    setLDNCDisplayYouResult('Information successfully submitted to blockchain, please move to part 2.')
      }

    } catch (err) {
      setLDNCDisplayYouResult('Error: Your information has not been submitted to the blockchain, please follow instructions link above.')

      console.log(err);
}
}



const LDNCPoemThemHandler = async () => {
  try {
     const { ethereum } = window;

     if (ethereum) {

         const theirName = document.getElementById('theirName').value;
         const theirAge = document.getElementById('theirAge').value;
         const theirLatitude = document.getElementById('theirLatitude').value;
         const theirLongitude = document.getElementById('theirLongitude').value;
         const theirWants = document.getElementById('theirWants').value;
         const theirGives = document.getElementById('theirGives').value;
         const theirReady = document.getElementById('theirReady').value;

         console.log(theirName, theirAge, theirLatitude, theirLongitude, theirWants, theirGives, theirReady)



const { ethereum } = window;
 const provider = new ethers.providers.Web3Provider(ethereum);
const signer = provider.getSigner();
const LDNCPoemContractThem = new ethers.Contract(LDNCPoemcontractAddress, LDNCPoemabi, signer);
const tx = await LDNCPoemContractThem.Them(theirName, theirAge, theirLatitude, theirLongitude, theirWants, theirGives, theirReady)
setLDNCDisplayThemResult('Submitting to blockchain, please wait...')
const receipt = await tx.wait()
console.log(receipt)
setLDNCDisplayThemResult('Information successfully submitted to blockchain, please move to part 3.')

      }

    } catch (err) {
      setLDNCDisplayYouResult('Error: Your information has not been submitted to the blockchain, please follow instructions link above.')

      console.log(err);
}
}




const LDNCPoemSubmitHandler = async () => {
  try {
     const { ethereum } = window;

     if (ethereum) {
const { ethereum } = window;
 const provider = new ethers.providers.Web3Provider(ethereum);
const signer = provider.getSigner();

const LDNCPoemDLC = new ethers.Contract(LDNCPoemcontractAddress, LDNCPoemabi, signer);
const tx = await LDNCPoemDLC.Does_Love_Compute()
setLDNCDisplayFinalResult('Submitting to blockchain, please wait...')
const receipt = await tx.wait()
//console.log(receipt)
//console.log(receipt.events)
console.log(receipt.events.[0].args.[0])
//alert(receipt.events.[0].args.[0])
setLDNCDisplayFinalResult(receipt.events.[0].args.[0])
  }

} catch (err) {
  setLDNCDisplayFinalResult('Error: Your information has not been submitted to the blockchain, please follow instructions link above.')

  console.log(err);
}
}




  if (active) {

    return (




      <Accordion>
      <div className="mb-16 text-sm">
      -= IMPORTANT! =-
      <br />
      Please use Gnosis Chain only!
      <br />
     Please read above instructions before continuing.
      </div>


      <AccordionItem title="1. Insert your info" >

                <form>
                    <fieldset>
                    <div className="mb-16 text-sm">
                      Your name
                    </div>
                      <div className="mb-12">
                        <Input
                        id="myName"
                          type="text"
                           />
                            </div>

                            <div className="mb-16 text-sm">
                              Your age
                            </div>
                            <div className="mb-12">
                      <Input

                      id="myAge"
                        type="number"
                       />
                        </div>

                        <div className="mb-16 text-sm">
                        Your latitude (no decimals required)
                        </div>
                        <div className="mb-12">
                    <Input
                    id="myLatitude"
                      type="number"
                       />
                  </div>

                  <div className="mb-16 text-sm">
                  Your longitude (no decimals required)
                  </div>
                  <div className="mb-12">
                <Input
                id="myLongitude"
                type="number"
                 />
                </div>

                <div className="mb-16 text-sm">
                Your wants
                </div>
                <div className="mb-12">
              <Input
              id="myWants"
              type="text"
               />
              </div>


              <div className="mb-16 text-sm">
              What can you give them?
              </div>
              <div className="mb-12">
            <Input
            id="myGives"
            type="text"
             />
            </div>


            <div className="mb-16 text-sm">
            Are you ready for love?
            </div>
            <div className="mb-12">
            <Select id="myReady">
    <option>true</option>
    <option>false</option>
  </Select>
          </div>

                  <div>
                    <h4>  {LDNCDisplayYouResult} </h4> </div>

                      <div className="mt-24 mb-32">
                        <Button
                        type="button"
                      onClick= {LDNCPoemYouHandler}
                      color="primary"
                        >Submit my info</Button>

                      </div>

                    </fieldset>
                  </form>
            </AccordionItem>

            <AccordionItem title="2. Insert your potential partners info">
            <form>
                <fieldset>
                <div className="mb-16 text-sm">
                  Their name
                </div>
                  <div className="mb-12">
                    <Input
                    id="theirName"
                      type="text"
                       />
                        </div>

                        <div className="mb-16 text-sm">
                          Their age
                        </div>
                        <div className="mb-12">
                  <Input

                  id="theirAge"
                    type="number"
                   />
                    </div>

                    <div className="mb-16 text-sm">
                    Their latitude (no decimals required)
                    </div>
                    <div className="mb-12">
                <Input
                id="theirLatitude"
                  type="number"
                   />
              </div>

              <div className="mb-16 text-sm">
              Their longitude (no decimals required)
              </div>
              <div className="mb-12">
            <Input
            id="theirLongitude"
            type="number"
             />
            </div>

            <div className="mb-16 text-sm">
            Your wants
            </div>
            <div className="mb-12">
            <Input
            id="theirWants"
            type="text"
             />
            </div>


            <div className="mb-16 text-sm">
            What can they give you?
            </div>
            <div className="mb-12">
            <Input
            id="theirGives"
            type="text"
             />
            </div>


            <div className="mb-16 text-sm">
            Are they ready for love?
            </div>
            <div className="mb-12">
            <Select id="theirReady">
            <option>true</option>
            <option>false</option>
            </Select>
            </div>


              <div>
                <h4>  {LDNCDisplayThemResult} </h4> </div>
                  <div className="mt-24 mb-32">
                    <Button
                    type="button"
                  onClick={LDNCPoemThemHandler}
                    color="primary"

                    >Submit their info</Button>
                  </div>


                </fieldset>
              </form>




            </AccordionItem>



            <AccordionItem title="3. Check compatibility">

                  <div className="mt-24 mb-32">

                  <div>
                    <h4>  {LDNCDisplayFinalResult} </h4> </div>
                    <Button
                    type="button"
                    onClick={LDNCPoemSubmitHandler}
                     color="primary" >Does Love Compute?</Button>


                  </div>
                  <div id="ac-display-mintInfo" > </div>
                                    </AccordionItem>
                                    </Accordion>


    );
  }
  return (
      <div>
      </div>
  );
};

export default LDNCPoem;

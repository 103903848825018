import React from 'react';

// import section header

import GenericSection from '../components/sections/GenericSection';



import SearchTable from './searchtable.js';


class Poems extends React.Component {




  render() {


    return (
      <React.Fragment>

        <GenericSection topDivider className="center-content">
          <div className="reveal-from-bottom">

          <h2 className="mt-0">Poem List</h2>
          <p className="mt-0">Select poem for further details</p>



          </div>
          <SearchTable />

        </GenericSection>

      </React.Fragment>
    );
  }
}

export default Poems;


import React from 'react';
import Button from '../components/elements/Button';

//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
//import paginationFactory from 'react-bootstrap-table2-paginator';


const { SearchBar, ClearSearchButton } = Search;



const linkFormatterB = (cell, row, rowIndex) => {
  return (
    <a href={cell} target="_blank" rel="noopener noreferrer">
      Link
    </a>
  );
};


const products = [

 {
        id: 1,
        'name': 'Fuck James Blunt',
  	'owner': 'TBC',
        plink:'./Fuck_James_Blunt'

      },
{
        id: 2,
        'name': 'The Ones to Blame',
        'owner': 'TBC',
        plink:'./The_Ones_to_Blame'

      },
 {
        id: 3,
        'name': 'Heart Shaped Chocolates For One',
        'owner': 'TBC',
        plink:'./Heart_Shaped_Chocolates_For_One'

      },
      {
        id: 4,
        'name': 'Lights Out',
        'owner': 'TBC',
        plink:'./Lights_Out'

      },
 {
        id: 5,
        'name': 'To Peacock',
        'owner': 'TBC',
        plink:'./To_Peacock'

      },

 {
        id: 6,
        'name': 'The Magician',
        'owner': 'TBC',
        plink:'./The_Magician'

      },
 {
        id: 7,
        'name': 'Four Silhouettes',
        'owner': 'TBC',
        plink:'./Four_Silhouettes'

      },
 {
        id: 8,
        'name': 'Love: Does Not Compute',
        'owner': 'TBC',
        plink:'./Love_Does_Not_Compute'

      },
 {
        id: 9,
        'name': '10101',
        'owner': 'TBC',
        plink:'./10101'

      },
 {
        id: 10,
        'name': 'Clay People',
        'owner': 'TBC',
        plink:'./Clay_People'

      },
 {
        id: 11,
        'name': 'Spin to Win',
        'owner': 'TBC',
        plink:'./Spin_to_win'

      },
 {
        id: 12,
        'name': 'Fireworks',
        'owner': 'TBC',
        plink:'./Fireworks'

      },
 {
        id: 13,
        'name': 'Funfair',
        'owner': 'TBC',
        plink:'./Funfair'

      },
 {
        id: 14,
        'name': 'Missed Minutes',
        'owner': 'TBC',
        plink:'./Missed_Minutes'

      },
 {
        id: 15,
        'name': 'The Gift',
        'owner': 'TBC',
        plink:'./The_Gift'

      },
 {
        id: 16,
        'name': 'The Cutlery Drawer',
        'owner': 'TBC',
        plink:'./The_Cutlery_Drawer'

      },
 {
        id: 17,
        'name': 'Pause',
        'owner': 'TBC',
        plink:'./Pause'

      },
 {
        id: 18,
        'name': 'Heart',
        'owner': 'TBC',
        plink:'./Heart'

      },
 {
        id: 19,
        'name': 'Pipes',
        'owner': 'TBC',
        plink:'./Pipes'

      },
 {
        id: 20,
        'name': 'Ground Zero',
        'owner': 'TBC',
        plink:'./Ground_Zero'

      },
      {
        id: 21,
        'name': 'To the Core',
        'owner': 'TBC',
        plink:'./To_the_Core'

      },
 {
        id: 22,
        'name': 'Statistically Yours',
        'owner': 'TBC',
        plink:'./Statistically_Yours'

      },
      {
        id: 23,
        'name': 'Home',
        'owner': 'TBC',
        plink:'./Home_Poem'

      },
 {
        id: 24,
        'name': 'Digital Dating',
        'owner': 'TBC',
        plink:'./Digital_Dating'

      },
 {
        id: 25,
        'name': 'The Fig',
        'owner': 'TBC',
        plink:'./The_Fig'

      },
 {
        id: 26,
        'name': 'First Kiss',
        'owner': 'TBC',
        plink:'./First_Kiss'

      },
 {
        id: 27,
        'name': 'See-saw',
        'owner': 'TBC',
        plink:'./See_saw'

      },
 {
        id: 28,
        'name': 'Compliments',
        'owner': 'TBC',
        plink:'./Compliments'

      },
 {
        id: 29,
        'name': 'The Beautiful Mundane',
        'owner': 'TBC',
        plink:'./The_Beautiful_Mundane'

      },
 {
        id: 30,
        'name': 'Fragile Perfection',
        'owner': 'TBC',
        plink:'./Fragile_Perfection'

      },
 {
        id: 31,
        'name': 'On Marriage',
        'owner': 'TBC',
        plink:'./On_Marriage'

      }, {
        id: 32,
        'name': 'The Future',
        'owner': 'TBC',
        plink:'./The_Future'

      },

    ]

    const columns = [{
      dataField: 'id',
      text: 'Poem ID',
      hidden: true
    }, {
      dataField: 'name',
      text: '',
       sort: true
    }, {
      dataField: 'owner',
      text: 'Owner',
       sort: true,
       hidden: true

    }, {
    dataField: "plink",
    text: "Poem Page",
    formatter: linkFormatterB,
    hidden: true

  }
];




    const options = {
            pageStartIndex: 0,

            showTotal: true,

          };


          const selectRow = {
            mode: 'radio',
            clickToSelect: true,
            clickToExpand: true,
            hideSelectColumn: true,
            bgColor: '#228b22'
          };


          const expandRow = {
            onlyOneExpanding: true,
            renderer: row => (
                  <div>
                <h3>{ `${row.name}` }</h3>

                <Button tag="a" href={ `${row.plink}` } color="primary" target="_blank" rel="noopener noreferrer">Visit Poem Page</Button>
                <br />
                <br />
                <p>Visit the poem page for further details</p>
                <Button disabled tag="a" color="primary" target="_blank" rel="noopener noreferrer">Buy Poem NFT</Button>
                <br />
                <br />
                <p>
                View the poem NFT on marketplace
                                </p>
              </div>
            ),

            showExpandColumn: false,

  };


const SearchTable= () => (

<ToolkitProvider
  keyField="id"
  data={ products }
  columns={ columns }
  search


>
  {
    props => (

      <div>

      <SearchBar { ...props.searchProps }
      className="custome-search-field"
          srText=""
          delay={ 1000 }
          placeholder="Search poems"


      />
        <ClearSearchButton { ...props.searchProps } />


        <BootstrapTable
          { ...props.baseProps }
          bootstrap4
          striped
          hover= '#00BFFF'
          condensed
          selectRow={ selectRow }
          expandRow={ expandRow }


                />
      </div>
    )
  }
</ToolkitProvider>


);
  export default SearchTable;

import React from 'react';
// import section header

import GenericSection from '../components/sections/GenericSection';

import Image from '../components/elements/Image';

class Shop extends React.Component {



  render() {


    return (
      <React.Fragment>
        <GenericSection topDivider className="center-content">
        Shop Coming Soon!

          <div className="reveal-from-bottom">
            <a
                >
              <Image
                src={require('./../assets/images/LDNC-Logo-Top.png')}
                alt="Logo"
                width={712}
                height={400} />
            </a>
          </div>

        </GenericSection>

      </React.Fragment>
    );
  }
}

export default Shop;

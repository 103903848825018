import React from 'react';
import classNames from 'classnames';
import Button from '../../components/elements/Button';
import Modal from '../../components/elements/Modal';



  class MintNFTInstructions extends React.Component {

    state = {
      showModal: false
    }

    openModal = (e) => {
      e.preventDefault();
      this.setState({ showModal: true });
    }

    closeModal = (e) => {
      e.preventDefault();
      this.setState({ showModal: false });
    }

    render() {
      return (
        <div>
        <Button color="primary" onClick={this.openModal}>Instructions</Button>

        <Modal
        id="demo-modal"
        show={this.state.showModal}
        handleClose={this.closeModal}>
          <h2 class="mt-0">Instructions</h2>
          <p class="m-0">Video Instructions and instructions go here</p>
       </Modal>
       </div>
      );
    }
  }

      export default MintNFTInstructions;

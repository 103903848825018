import React from 'react';
// import section header
import GenericSection from '../components/sections/GenericSection';

// import some required elements
import Image from '../components/elements/Image';
import Button from '../components/elements/Button';

class The_Magician extends React.Component {



  render() {



    return (
      <React.Fragment>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">The Magician</h2>
            <h4 className="mt-0">Owner: TBC </h4>
            <Button color="dark" wideMobile>View at OpenSea</Button>
              <br />
              <br />

            <p>
              Lorem ipsum dolor sit amet, <a href="#0">consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p>

            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/Poem_Images/The_Magician.png')}
                alt="The Magician"
                width={712}
                height={400} />
            </figure>

          </div>
        </GenericSection>






      </React.Fragment>
    );
  }
}

export default The_Magician;

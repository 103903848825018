import React from 'react';
// import section header
// import sections
import GenericSection from '../components/sections/GenericSection';
import ETHLogin from '../utils/ETHLogin';
import MintNFT from '../components/sections/MintNFT';
import MintNFTInstructions from '../components/sections/MintNFTInstructions';

import Image from '../components/elements/Image';





class Claim_NFT extends React.Component {



  render() {


    return (
      <React.Fragment>

      <GenericSection topDivider className="center-content">

    
        <MintNFTInstructions/>
        <br />

        <ETHLogin/>
        <MintNFT/>
        </GenericSection>


      </React.Fragment>
    );
  }
}

export default Claim_NFT;

import React from 'react';
import Button from '../components/elements/Button';
import { useWeb3React } from "@web3-react/core"
import { injected } from "../components/Connectors"
import { shortenAddress } from '../utils/shortenAddress';
import classNames from 'classnames';


const ETHLogin = () => {
  const { activate, active, account, deactivate } = useWeb3React();

  if (active) {
    return (
      <div>
      <Button color="primary" onClick={deactivate}>Sign out</Button>
      <p className="mb-16 text-sm">
        Wallet connected:  {shortenAddress(account)}
        </p>
      </div>



    );
  }

  return (
      <div>
      <Button color="primary" onClick={() => activate(injected)}>Connect Wallet</Button>
      <p className="mb-16 text-sm">
        Please connect your browser wallet and switch to the Gnosis Chain
        </p>
    </div>
  );
};

export default ETHLogin;
